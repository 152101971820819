interface IProject {
  title: string;
  url: string;
  company: string;
  website: string;
  work: string;
  technologies: string;
  image: string;
}

const Projects: IProject[] = [
  {
    title: 'Spare Agricultural Parts - Web Shop',
    url: 'https://agrita.rs',
    company:
      'This company specializes in trading and selling spare parts for agricultural and heavy machinery in Serbia',
    website:
      'Clients can see all of the articles added to the website neatly categorized, and upon registration they can place an order.',
    work:
      'Designed and developed front and backend. Worked on localization, mailing, search engine optimization and minimizing page load time.',
    technologies: 'Laravel / Vue.js / mySQL',
    image: 'projects/agrita.png',
  },
  {
    title: 'Spare Agricultural Parts - Admin Panel',
    url: 'https://agrita.demo.luka-korolija.com',
    company: 'Admin panel for the above mentioned project',
    website:
      'Only admins have access to this segment and with this dashboard he can fully customize the articles, categories, manufacturers that are being displayed, as well as approve users and create discounts.',
    work:
      'Designed and developed backend and database from scratch. Created a REST API that is consumed by the Laravel application. Demo link of the application included.',
    technologies: 'Laravel / Vue.js / mySQL',
    image: 'projects/agrita-dashboard.png',
  },
  {
    title: 'Balloon Store - Web Presentation',
    url: 'https://vascarobnikutak.rs',
    company:
      'This company specializes in making party packs with all kinds of balloons.',
    website:
      'The website has a presentation of their business, a map with a location as well as the contact form, and dynamically generated pages with the images they upload to the server.',
    work:
      'Designed and developed frontend. Used firebase API for storing the categories and photos. Express.js on backend for contact form, and Next.js on frontend to make use of server-side rendering for better search engine optimization.',
    technologies: 'Next.js / Express.js / Firebase',
    image: 'projects/vascarobni.png',
  },
];

export default Projects;
