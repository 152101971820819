import React from 'react';

// @ts-ignore
import Fade from 'react-reveal/Fade';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Image from '../components/image';

import Projects from '../data/projectList';
import { useComponentMounted } from '../hooks/componentMounted';

const Work = () => {
  const { componentMounted } = useComponentMounted();
  const ProjectCards = Projects.map((proj, index) => {
    return (
      <Fade delay={index * 100} when={componentMounted} duration={400} key={proj.title} top>
        <div className="card mb-4">
          <div className="row no-gutters">
            <div className="col-md-4">
              <a href={proj.url}>
                <Image filename={proj.image} alt={proj.title} />
              </a>
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title mb-1 position-relative">
                  {proj.title}{' '}
                  <a href={proj.url} className="open-in-browser position-absolute ml-2">
                    <Image
                      filename="open-in-browser-icon.png"
                      alt="open in browser icon"
                    />
                  </a>
                </h5>
                <h6 className="text-muted text-light">{proj.technologies}</h6>
                <hr className="mt-1 mb-2" />
                <span className="card-text">
                  <h6 className="mb-0">Company description:</h6>
                  <p>{proj.company}</p>
                </span>
                <span className="card-text">
                  <h6 className="mb-0">Website description:</h6>
                  <p>{proj.website}</p>
                </span>
                <span className="card-text">
                  <h6 className="mb-0">Work:</h6>
                  <p>{proj.work}</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    );
  });
  return (
    <Layout>
      <SEO title="Work" />
      <div className="work-content">
        <Fade duration={300} when={componentMounted} top>
          <h2 className="text-center mb-3">Projects</h2>
        </Fade>
        {ProjectCards}
      </div>
    </Layout>
  );
};

export default Work;
